import {
  channelCreate,
  channelRead,
  channelUpdate,
  channelDelete,
  channelObserve,
  channelGet
} from "../../services/api/channel"
import { streamCreate, streamGet } from "../../services/api/stream"
import { STREAM_CREATE_SUCCESS, STREAM_DELETE_SUCCESS, STREAM_GET_SUCCESS } from "../actions/StreamActions"
import { workspaceRead } from '../../services/api/workspace'
import { widgetCreate, widgetGet } from "../../services/api/widget"
import { WIDGET_CREATE_SUCCESS, WIDGET_DELETE_SUCCESS, WIDGET_GET_SUCCESS } from "../actions/WidgetActions"
import { Hub } from 'aws-amplify'
import { MODAL_CHANNEL_PUBLIC_ADDED } from "../../utils/Constant"
import * as K from "../../utils/Constant"
import { eventCreate } from "../../services/api/event"
import { toastSuccess, toastError } from "../../shared/platform/Toast"
import { triggerEmailPublicChannelAdded } from "../../utils/TriggerHelpers"
import { restapiChannelSubscribe, restapiChannelUnsubscribe } from "../../services/rest/apiAWS"
import { handleMessageRead } from "./MessageActions"
import { COPYTRADING_COPIER_DELETE_SUCCESS } from "./TradesyncActions"
export const CHANNEL_CREATE_REQUEST = 'CHANNEL_CREATE_REQUEST'
export const CHANNEL_CREATE_SUCCESS = 'CHANNEL_CREATE_SUCCESS'
export const CHANNEL_CREATE_FAIL = 'CHANNEL_CREATE_FAIL'

export const CHANNEL_READ_REQUEST = 'CHANNEL_READ_REQUEST'
export const CHANNEL_READ_SUCCESS = 'CHANNEL_READ_SUCCESS'
export const CHANNEL_READ_FAIL = 'CHANNEL_READ_FAIL'

export const CHANNEL_GET_REQUEST = 'CHANNEL_GET_REQUEST'
export const CHANNEL_GET_SUCCESS = 'CHANNEL_GET_SUCCESS'
export const CHANNEL_GET_FAIL = 'CHANNEL_GET_FAIL'

export const CHANNEL_UPDATE_REQUEST = 'CHANNEL_UPDATE_REQUEST'
export const CHANNEL_UPDATE_SUCCESS = 'CHANNEL_UPDATE_SUCCESS'
export const CHANNEL_UPDATE_FAIL = 'CHANNEL_UPDATE_FAIL'

export const CHANNEL_DELETE_REQUEST = 'CHANNEL_DELETE_REQUEST'
export const CHANNEL_DELETE_SUCCESS = 'CHANNEL_DELETE_SUCCESS'
export const CHANNEL_DELETE_FAIL = 'CHANNEL_DELETE_FAIL'

export function handleChannelCreate(owner, user, name, description, info, categories, isPublic, status, workspace, shared, media) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CHANNEL_CREATE_REQUEST,
        payload: { owner: owner, user: user, name: name, description: description, info: info, categories: categories, public: isPublic, status: status, shared: shared, media },
      })
      channelCreate(owner, user, name, description, info, categories, isPublic, status, media)
        .then(channel => {
          if (channel != null) {
            streamCreate(channel.owner, channel.user, channel)
              .then(stream => {
                workspaceRead(stream?.owner)
                  .then(workspaces => {
                    if (stream != null && workspaces?.length > 0) {
                      widgetCreate(stream.owner, workspace, stream, 250, 550, 400, 300, 300, 300, stream.channel.name)
                        .then(widget => {
                          // open next guide
                          Hub.dispatch(K.HUB_OPENGUIDECHANNELWIDGET)
                          dispatch({
                            type: WIDGET_CREATE_SUCCESS,
                            payload: widget,
                          })
                          toastSuccess('toast_text_9')
                          resolve()
                        })
                    }
                  })
                dispatch({
                  type: STREAM_CREATE_SUCCESS,
                  payload: stream,
                })
              })
            dispatch({
              type: CHANNEL_CREATE_SUCCESS,
              payload: channel,
            })

            const sharedUserIds = shared?.map(u => u?.id)
            channelUpdate(owner, channel.id, channel.name, channel.description, channel.info, channel.public, channel.status,
              [{ channelId: channel.id, categories: categories, labels: [channel.name], disable: false }], channel.categories, sharedUserIds)
              .then(channelUpdated => {
                dispatch({
                  type: CHANNEL_UPDATE_SUCCESS,
                  payload: channelUpdated,
                })
                const _ = shared?.filter(u => u?.id !== user?.id)?.forEach(connectedUser => {
                  try {
                    const author = user?.name === "Unknown" ? user?.username : user?.name
                    const payload = JSON.stringify({ channel: channelUpdated?.id, author: author })
                    eventCreate(connectedUser?.owner, "modal", "private_channel", payload)
                  } catch (e) {
                    console.error('Private channel notify failed:', e)
                  }
                })
              })
              .catch(err => { console.error('Channel update failed:', err) })
          } else {
            toastError('toast_text_10')
            reject()
          }
          if (isPublic && shared == null) {
            setTimeout(() => {
              Hub.dispatch(K.HUB_MODAL, { event: MODAL_CHANNEL_PUBLIC_ADDED, data: { channel: channel }, message: 'show modal' });
              triggerEmailPublicChannelAdded(channel?.owner, { name: channel?.name })
            }, 2000)
          }
        })
        .catch(err => {
          dispatch({
            type: CHANNEL_CREATE_FAIL,
            error: true,
            payload: new Error(err),
          })
          toastError('toast_text_10')
          reject()
        })
    })
  }
}

export function handleChannelRead(owner, user) {
  return dispatch => {
    dispatch({
      type: CHANNEL_READ_REQUEST,
      payload: owner,
    })
    channelRead(owner, user)
      .then(data => {
        dispatch({
          type: CHANNEL_READ_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: CHANNEL_READ_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function handleChannelGet(channelId) {
  return dispatch => {
    dispatch({
      type: CHANNEL_GET_REQUEST,
      payload: channelId,
    })
    channelGet(channelId)
      .then(data => {
        dispatch({
          type: CHANNEL_GET_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: CHANNEL_GET_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function handleChannelUpdate(owner, channelId, name, description, info, isPublic, status, filterRules, categories, shared) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CHANNEL_UPDATE_REQUEST,
        payload: { owner: owner, channelId: channelId, name: name, description: description, info: info, isPublic: isPublic, filterRules: filterRules, status: status, shared: shared },
      })
      const sharedUserIds = shared?.some(el => el?.id != null) ? shared?.map(u => u?.id) : shared
      channelUpdate(owner, channelId, name, description, info, isPublic, status, filterRules, categories, sharedUserIds)
        .then(data => {
          dispatch({
            type: CHANNEL_UPDATE_SUCCESS,
            payload: data,
          })
          toastSuccess('toast_text_11')
          resolve(data)
        })
        .catch(err => {
          dispatch({
            type: CHANNEL_UPDATE_FAIL,
            error: true,
            payload: new Error(err),
          })
          toastError('toast_text_12')
          reject(err)
        })
    })
  }
}

export function handleChannelDelete(owner, channelId) {
  return dispatch => {
    dispatch({
      type: CHANNEL_DELETE_REQUEST,
      payload: { owner: owner, channelId: channelId },
    })
    channelDelete(owner, channelId)
      .then(data => {
        dispatch({
          type: CHANNEL_DELETE_SUCCESS,
          payload: data,
        })
      })
      .catch(err => {
        dispatch({
          type: CHANNEL_DELETE_FAIL,
          error: true,
          payload: new Error(err),
        })
      })
  }
}

export function observeChannel(owner) {
  return dispatch => {
    channelObserve(owner)
  }
}

export function handleChannelObserver(data) {
  return dispatch => {
    switch (data.opType) {
      case 'INSERT':
        dispatch({
          type: CHANNEL_CREATE_SUCCESS,
          payload: data.element,
        })
        break;
      case 'UPDATE':
        dispatch({
          type: CHANNEL_UPDATE_SUCCESS,
          payload: data.element,
        })
        break;
      case 'DELETE':
        dispatch({
          type: CHANNEL_DELETE_SUCCESS,
          payload: data.element,
        })
        break;
      default:
        break;
    }
  }
}

export function handleChannelObserverAPI(event, data) {
  return dispatch => {
    switch (event) {
      case 'INSERT':
        dispatch({
          type: CHANNEL_CREATE_SUCCESS,
          payload: data,
        })
        break;
      case 'UPDATE':
        dispatch({
          type: CHANNEL_UPDATE_SUCCESS,
          payload: data,
        })
        break;
      case 'DELETE':
        dispatch({
          type: CHANNEL_DELETE_SUCCESS,
          payload: data,
        })
        break;
      default:
        break;
    }
  }
}

export function handleChannelSubscribe(owner, channelId, extraId, workspaceId) {
  return dispatch => {
    restapiChannelSubscribe(owner, extraId == null ? channelId : null, extraId, workspaceId)
      .then(response => {
        dispatch(handleChannelSubscribeResult(response))
        extraId == null
          ? toastSuccess('toast_text_9')
          : toastSuccess('toast_text_15')
      })
      .catch(err => {
        console.error('error subscribe with channel:', channelId, ' extra:', extraId)
        extraId == null
          ? toastError('toast_text_10')
          : toastError('toast_text_16')
      })
  }
}
export function handleChannelUnsubscribe(owner, channelId, extraId) {
  return dispatch => {
    restapiChannelUnsubscribe(owner, extraId == null ? channelId : null, extraId)
      .then(response => {
        dispatch(handleChannelUnsubscribeResult(response))
        extraId == null
          ? toastSuccess('toast_text_13')
          : toastSuccess('toast_text_19')
      })
      .catch(err => {
        console.error('error unsubscribe with channel:', channelId, ' extra:', extraId)
        extraId == null
          ? toastSuccess('toast_text_14')
          : toastSuccess('toast_text_20')
      })
  }
}

export function handleChannelSubscribeResult(response) {
  return dispatch => {
    channelGet(response?.channelId)
      .then(channel => {
        dispatch({
          type: CHANNEL_GET_SUCCESS,
          payload: channel
        })
        streamGet(response?.streamId)
          .then(stream => {
            dispatch({
              type: STREAM_GET_SUCCESS,
              payload: stream
            })
            widgetGet(response?.widgetId)
              .then(widget => {
                dispatch({
                  type: WIDGET_GET_SUCCESS,
                  payload: widget
                })
                for (let rule of channel?.filterRules || []) {
                  if (!rule?.disable) {
                    dispatch(handleMessageRead(rule, 99, null))
                  }
                }
              }).catch(error => {
                console.error(error)
              })
          }).catch(error => {
            console.error(error)
          })
      }).catch(error => {
        console.error(error)
      })
  }
}

export function handleChannelUnsubscribeResult(response) {
  return dispatch => {
    dispatch({
      type: CHANNEL_DELETE_SUCCESS,
      payload: response?.channelId
    })
    dispatch({
      type: STREAM_DELETE_SUCCESS,
      payload: response?.streamId
    })
    dispatch({
      type: WIDGET_DELETE_SUCCESS,
      payload: response?.widgetId
    })
    dispatch({
      type: COPYTRADING_COPIER_DELETE_SUCCESS,
      payload: response?.copier
    })
  }
}